<template>
  <section class="section">
    <div class="columns">
      <div class="column is-3"></div>
      <div class="column">
        <h1 class="title has-text-centered has-fancy-underline padding-bottom-1rem">
          Mapa strony
        </h1>
        <div class="content">
          <h2 class="subtitle">Rankingi</h2>
          <ul class="'">
            <li>
              <router-link :to="{ name: 'brokerlist' }">Ranking najlepszych brokerów</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'rankinglist' }">Lista wszystkich rankingów</router-link>
            </li>
            <li v-for="ranking in rankings" :key="ranking.slug">
              <router-link :to="{ name: 'brokerranking', params: { slug: ranking.slug } }">{{ ranking.title
                }}</router-link>
            </li>
          </ul>

          <h2 class="subtitle">Baza wiedzy</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'articlelist' }">Lista wszystkich artykułów</router-link>
            </li>
            <li v-for="article in articles" :key="article.slug">
              <router-link :to="{ name: 'article', params: { slug: article.slug } }">{{ article.title }} - {{
                article.teaser }}</router-link>
            </li>
          </ul>

          <h2 class="subtitle">Pojęcia inwestycyjne</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'dictionarylist' }">List wszystkich pojęć inwestycyjnych</router-link>
            </li>
            <li v-for="definition in definitions" :key="definition.slug">
              <router-link :to="{ name: 'definition', params: { slug: definition.slug } }">{{ definition.title }} - {{
                definition.teaser }}</router-link>
            </li>
          </ul>

          <h2 class="subtitle">Godziny otwarcia giełd</h2>
          <ul>
            <li v-for="exchange in exchanges" :key="exchange.name">
              <router-link :to="{ name: 'exchanges', query: { show: exchange.name } }">Godziny otwarcia {{ exchange.name
                }}</router-link>
            </li>
          </ul>

          <h2 class="subtitle">Strony Brokerów</h2>
          <ul>
            <li v-for="broker in brokers" :key="broker.slug">
              <router-link :to="{ name: 'brokerdetail', params: { slug: broker.slug } }">{{ broker.name }} - {{
                broker.description }}</router-link>
            </li>
          </ul>
          <h2 class="subtitle">Porównania brokerów</h2>
          <div v-for="broker1 in brokers" :key="broker1.slug">
            <h3 class="margin-top-1rem">
              {{ broker1.name }} w porównaniu do innych
            </h3>
            <ul>
              <li v-for="broker2 in brokers.filter((b) => b.slug != broker1.slug)" :key="broker2.slug">
                <router-link :to="{
                  name: 'compare',
                  params: { slugs: [broker1.slug, broker2.slug].join('-vs-') },
                }">Co lepsze? {{ broker1.name }} czy
                  {{ broker2.name }}?</router-link>
              </li>
            </ul>
          </div>

          <h2 class="subtitle">Inne ale ważne strony</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'aboutus' }">O nas i kontakt</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'exchanges' }">Godziny otwarcia giełd</router-link>
            </li>
            <li>
              <a target="_blank" ref="noreferrer noopener" href="/static/Polityka_Prywatności_jakibrokerpl.pdf">Polityka
                prywatności</a>
            </li>
            <li>
              <a target="_blank" ref="noreferrer noopener" href="/static/Regulamin_jakibrokerpl.pdf">Regulamin</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="column is-3"></div>
    </div>
  </section>
</template>

<script>
import articles from "@/articles.json";
import definitions from "@/definitions.json";
import exchanges from "@/exchanges.json";

const sortedDefinitions = definitions.sort((a, b) => a.title > b.title);

export default {
  name: "Sitemap",
  computed: {
    rankings() {
      return this.$store.state.rankings;
    },
    brokers() {
      return this.$store.state.brokers;
    },
    articles() {
      return articles.filter((elem) => elem.isPublic || window.location.origin.includes("localhost") || window.location.origin.includes("jakibroker-testing"));
    },
    definitions() {
      return sortedDefinitions;
    },
    exchanges() {
      return exchanges;
    },
  },
};
</script>
