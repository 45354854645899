<template>
  <div>
    <div class="box">
      <template>
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title"
          v-if="brokers.length > 1">Porównanie</h2>
        <div class="columns is-mobile is-vcentered has-background-white jkb-sticky has-text-black">
          <div class="column" v-for="broker in brokersToCompare" :key="broker.slug">
            <a :href="broker.create_account_link + '/compare/logo' + addRef()">
              <figure class="container image jkb-image-max-width jkb-centered-image">
                <img :src="broker.imageSrc" />
              </figure>
            </a>
          </div>
        </div>
      </template>

      <div v-if="isAllFields">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Deklaracja zrozumienia ryzyka inwestycyjnego
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-justified" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <small>{{ broker.disclaimer }}</small>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('rate')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Ocena
        </h2>
        <div class="columns is-mobile has-text-centered">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <small>
              <progress class="progress" :value="broker.rate" max="5" :class="{
                'is-danger': broker.rate < 2,
                'is-warning': broker.rate < 4 && broker.rate >= 2,
                'is-success': broker.rate >= 4,
              }"></progress>
              {{ broker.rate }}
            </small>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('popularity')" @mouseover="mouseOver.popularity = true"
        @mouseleave="mouseOver.popularity = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Popularność
          <span @click="showInfoModalWith('popularity')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.popularity || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-justified" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <template v-if="broker.popularity">
              <strong> {{ broker.popularity }}</strong> użytkowników JakiBroker.pl wybrało <strong>{{
                broker.name
              }}</strong>
            </template>
            <template v-else>
              <span class="icon has-text-info">
                <font-awesome-icon icon="hourglass-half"></font-awesome-icon> </span>Nowy broker - zbieramy dane o
              <strong>
                {{ broker.name }}
              </strong>
            </template>
            &nbsp;
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('description')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Opis
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-justified" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <strong>{{ broker.name }}</strong> - {{ broker.description }}
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('spread')" @mouseover="mouseOver.spread = true" @mouseleave="mouseOver.spread = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Spread
          <span @click="showInfoModalWith('spread')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.spread || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="features-list">
              <li v-for="spreadInfo in broker.spread" :key="spreadInfo">
                {{ spreadInfo }}
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('interestRate')" @mouseover="mouseOver.interestRate = true"
        @mouseleave="mouseOver.interestRate = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Oprocentowanie wolnych środków
          <span @click="showInfoModalWith('interestRate')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.interestRate || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <template v-if="broker.interestRate.length">
              <ul class="features-list">
                <li v-for="interestRate in broker.interestRate" :key="interestRate">
                  {{ interestRate }}
                </li>
              </ul>
            </template>
            <template v-else>
              <span class="icon has-text-danger">
                <font-awesome-icon icon="times-circle"></font-awesome-icon> </span>Brak
            </template>
          </div>
        </div>

        <hr />
      </div>

      <div v-if="shouldShow('commission')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Prowizje
          i opłaty</h2>

        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="features-list">
              <li v-for="commissionInfo in broker.commission" :key="commissionInfo">
                {{ commissionInfo }}
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('minInstallment')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Wpłata
          minimalna</h2>

        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            {{ broker.minInstallment }}
          </div>
        </div>
        <hr />
      </div>


      <div v-if="shouldShow('investmentProtection')" @mouseover="mouseOver.investmentProtection = true"
        @mouseleave="mouseOver.investmentProtection = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Ochrona przed ujemnym saldem
          <span @click="showInfoModalWith('investmentProtection')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.investmentProtection || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile" v-if="shouldShow('investmentProtection')">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            {{ broker.investmentProtection }}
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('leverage')" @mouseover="mouseOver.leverage = true"
        @mouseleave="mouseOver.leverage = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Dźwignia
          <span @click="showInfoModalWith('leverage')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.leverage || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            {{ broker.leverage }}
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('ecn')" @mouseover="mouseOver.ecn = true" @mouseleave="mouseOver.ecn = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          ECN
          <span @click="showInfoModalWith('ecn')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.ecn || isMobile()" class="clickable-icon has-text-link animated tada"
              icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <span class="icon has-text-success" v-if="broker.ecn">
              <font-awesome-icon icon="check-circle"></font-awesome-icon>
            </span>
            <span class="icon has-text-danger" v-else>
              <font-awesome-icon icon="times-circle"></font-awesome-icon>
            </span>
            <span>
              {{ broker.ecn ? "Tak" : "Nie" }}
            </span>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('currencies')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Waluta
          konta</h2>

        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="jkb-compare-list">
              <li v-for="currency in broker.currencies" :key="currency">
                <span class="flag-icon" :class="flags[currency.toLowerCase()]"></span>

                <span :class="{
                  'has-text-weight-bold has-fancy-underline':
                    currency == selectedAccountCurrency,
                }">&nbsp;{{ currency }}</span>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('platform')" @mouseover="mouseOver.platform = true"
        @mouseleave="mouseOver.platform = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Platforma
          <span @click="showInfoModalWith('platform')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.platform || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="margin-bottom-1rem jkb-compare-list">
              <li v-for="platform in allPlatforms" :key="platform">
                <span class="icon has-text-success" v-if="isIn(platform, broker.platforms)">
                  <font-awesome-icon icon="check-circle"></font-awesome-icon>
                </span>
                <span class="icon has-text-danger" v-else>
                  <font-awesome-icon icon="times-circle"></font-awesome-icon>
                </span>
                <span :class="{
                  'has-text-weight-bold has-fancy-underline':
                    platform == selectedPlatform,
                }">{{ platform }}</span>
              </li>
              <template v-for="platform in broker.platforms">
                <li v-if="!isIn(platform, allPlatforms)" :key="platform">
                  <span class="icon has-text-success">
                    <font-awesome-icon icon="check-circle"></font-awesome-icon>
                  </span>
                  <span>{{ platform }}</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('instruments')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Instrumenty</h2>

        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="margin-bottom-1rem jkb-compare-list">
              <li v-for="instrument in allInstruments" :key="instrument">
                <span class="icon has-text-success" v-if="isIn(instrument, broker.instruments)">
                  <font-awesome-icon icon="check-circle"></font-awesome-icon>
                </span>
                <span class="icon has-text-danger" v-else>
                  <font-awesome-icon icon="times-circle"></font-awesome-icon>
                </span>
                <span :class="{
                  'has-text-weight-bold has-fancy-underline':
                    instrument == selectedInstrument,
                }">{{ instrument }}</span><span v-if="broker.onlyCFD">&nbsp;(CFD)</span>
              </li>
              <template v-for="instrument in broker.instruments">
                <li v-if="!isIn(instrument, allInstruments)" :key="instrument">
                  <span class="icon has-text-success">
                    <font-awesome-icon icon="check-circle"></font-awesome-icon>
                  </span>
                  <span>{{ instrument }}</span><span v-if="broker.onlyCFD">&nbsp;(CFD)</span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('fractionalShares')" @mouseover="mouseOver.fractionalShares = true"
        @mouseleave="mouseOver.fractionalShares = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Akcje ułamkowe
          <span @click="showInfoModalWith('fractionalShares')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.fractionalShares || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <span class="icon has-text-success" v-if="broker.fractionalShares">
              <font-awesome-icon icon="check-circle"></font-awesome-icon>
            </span>
            <span class="icon has-text-danger" v-else>
              <font-awesome-icon icon="times-circle"></font-awesome-icon>
            </span>
            <span>
              {{ broker.fractionalShares ? "Tak" : "Nie" }}
            </span>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('countryOfRegulation')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Kraj
          regulacji</h2>

        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="margin-bottom-1rem jkb-compare-list">
              <li v-for="country in broker.countryOfRegulation" :key="country">
                <span class="flag-icon" :class="flags[country.toLowerCase()]"></span>

                <span :class="{
                  'has-text-weight-bold has-fancy-underline':
                    country == selectedCountryOfRegulation,
                }">&nbsp;{{ country }}</span>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('additionalRegulationInformation')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Dodatkowe informacje o regulacjach</h2>

        <div class="columns is-mobile">
          <div class="column has-text-justified" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <ul class="margin-bottom-1rem">
              <li v-for="info in broker.additionalRegulationInformation" :key="info">
                <small>{{ info }}</small>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('countryOfOrigin')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Kraj
          pochodzenia</h2>

        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <p class="margin-bottom-1rem">
              <span class="flag-icon" :class="flags[broker.countryOfOrigin.toLowerCase()]"></span>
              <span>&nbsp;{{ broker.countryOfOrigin }}</span>
            </p>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('fundingMethods')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Metody
          wpłaty</h2>

        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <FundingMethodsList class="margin-bottom-1rem" :fundingMethods="broker.fundingMethods" />
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('review')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Recenzja
          od JakiBroker.pl</h2>

        <div class="columns is-mobile">
          <div class="column" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <span v-if="broker.review">
              <router-link :to="{
                name: 'article',
                params: { slug: broker.review.slug },
              }">{{ broker.review.text }}</router-link></span>
            <span v-else class="has-text-danger">Recenzja dostępna wkrótce.</span>
          </div>
        </div>
        <hr />
      </div>

      <div v-if="shouldShow('kalkulatorgieldowy')" @mouseover="mouseOver.kalkulatorgieldowy = true"
        @mouseleave="mouseOver.kalkulatorgieldowy = false">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Kalkulator giełdowy
          <span @click="showInfoModalWith('kalkulatorgieldowy')">
            &nbsp;
            <font-awesome-icon v-show="mouseOver.kalkulatorgieldowy || isMobile()"
              class="clickable-icon has-text-link animated tada" icon="question"></font-awesome-icon>
          </span>
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-centered" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <template v-if="broker.kalkulatorgieldowy">
              <span class="icon has-text-success">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
              </span>
              <a :href="broker.kalkulatorgieldowy">Wspierane. Rozlicz PIT</a>
            </template>
            <template v-else>
              <span class="icon has-text-danger">
                <font-awesome-icon icon="times-circle"></font-awesome-icon> </span>Brak
            </template>
          </div>
        </div>
        <hr />
      </div>


      <div>
        <div class="columns is-mobile has-text-centered">
          <div class="column">
            <div v-if="isAllFields">
              <span>Brakuje informacji, której szukasz?&nbsp;</span><a href="https://forms.gle/xL1i6U69kZfA5VUR7"
                target="_blank" rel="noopener noreferrer">Daj
                nam znać!</a>
            </div>
          </div>
        </div>
        <hr v-if="isAllFields" />
      </div>

      <div v-if="shouldShow('disclaimer')">
        <h2
          class="subtitle is-6 has-text-centered notification is-light has-background-white is-info has-text-black jkb-section-title jkb-sticky-header">
          Deklaracja zrozumienia ryzyka inwestycyjnego
        </h2>
        <div class="columns is-mobile">
          <div class="column has-text-justified" v-for="(broker, index) in brokersToCompare" :key="broker.slug"
            :class="{ 'jkb-has-borders-column': index != brokersToCompare.length - 1 }">
            <small>{{ broker.disclaimer }}</small>
          </div>
        </div>
        <hr />
      </div>
      <div class="columns is-mobile has-text-centered">
        <div class="column" v-for="broker in brokersToCompare" :key="broker.slug">
          <a v-if="isMobile()" :href="broker.create_account_link + '/compare/button' + addRef()">Załóż konto w
            {{
              broker.name }}</a>
          <a v-else :href="broker.create_account_link + '/compare/button' + addRef()">
            <button class="button is-info is-medium">
              <span>ZAŁÓŻ KONTO W {{ broker.name }}</span>
              <span class="icon">
                <lord-icon animation="hover" target="button" params="200" palette="#ffffff;#000000"
                  src="/lordicon/20-love-heart-outline.json"></lord-icon>
              </span>
            </button>
          </a>
        </div>
      </div>
      <div v-if="!(isAllFields || isCompareRoute)" class="columns is-mobile has-text-centered">
        <div class="column">
          <a @click="seeFullComparison">zobacz pełne porównanie</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import FundingMethodsList from './FundingMethodsList';

export default {
  name: "SideBySideCompareColumns",
  components: {
    FundingMethodsList,
  },
  props: {
    brokers: {
      type: Array,
      required: false
    },
    fields: Array,
    isAllFields: { type: Boolean, default: true },
  },
  data() {
    return {
      mouseOver: {
        leverage: false,
        investmentProtection: false,
        interestRate: false,
        platform: false,
        spread: false,
        ecn: false,
        kalkulatorgieldowy: false,
        popularity: false,
        fractionalShares: false,
      },
      allPlatforms: [
        "Ires",
        "MetaTrader 4",
        "MetaTrader 5",
        "NinjaTrader",
        "WebTrader",
        "xStation 5",
        "xStation Mobile",
      ],
      allInstruments: ["Akcje", "Forex", "Indeksy", "Kryptowaluty", "Surowce"],
    };
  },
  methods: {
    shouldShow(field) {
      return this.isAllFields || this.fieldsToShow.includes(field);
    },
    seeFullComparison() {
      let slugs = [...this.brokers]
      slugs.sort();
      const slugsString = slugs.join("-vs-");
      this.$gtag.event("seeFullComparison", {
        event_category: "engagement",
        event_label: slugsString,
      });
      this.$router.push({
        name: "compare",
        params: { slugs: slugsString },
      });
    }
  },
  computed: {
    fieldsToShow() {
      return [...this.fields, 'disclaimer'];
    },
    selectedPlatform() {
      return this.$store.state.filter.platform;
    },
    selectedInstrument() {
      return this.$store.state.filter.instrument;
    },
    selectedAccountCurrency() {
      return this.$store.state.filter.accountCurrency;
    },
    selectedCountryOfRegulation() {
      return this.$store.state.filter.countryOfRegulation;
    },
    isCompareRoute() {
      return this.$route.name === "compare";
    },
    brokersToCompare() {
      let brokers = [...this.brokers]
      brokers.sort()
      return brokers.map(this.loadBroker);
    }
  },
};
</script>

<style scoped>
.has-text-underline {
  text-decoration: underline;
}

.jkb-image-max-width {
  max-width: 128px;
}

.jkb-centered-image {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .jkb-image-max-width {
    max-width: 64px;
  }
}


.jkb-section-title {
  font-weight: bold;
}

.jkb-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 54px;
  left: 0;
  right: 0;
  z-index: 29;
  border-bottom: 2px solid whitesmoke;
}

.jkb-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 209px;
  left: 0;
  right: 0;
  z-index: 28;
}

@media screen and (max-width: 768px) {
  .jkb-sticky-header {
    top: 140px;
  }
}

.jkb-compare-list {
  list-style-type: none;
}

.jkb-has-borders-column {
  border-right: 2px solid whitesmoke;
}
</style>